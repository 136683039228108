.hero {

}

.hero_container {
    max-width: 1200px;
    margin: 200px auto 100px;
    width: 75%;

}

/* p {
    
    margin: auto;
} */