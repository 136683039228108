@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, p, a, button {
    /* font-family: 'Noto Sans JP', sans-serif; */
    /* font-family: 'Shippori Mincho B1', serif; */
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    font-style: normal;
    text-transform: none;
    color: inherit;
}

h1, .h1 {
    font-size: 45px;
    line-height: 50px;
    font-weight: 700;
    margin: 0 0 10px;
}

h2, .h2 {
    font-size: 32px;
    line-height: 35px;
    font-weight: 800;
    margin: 0 0 10px;
}

h3, .h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin: 0 0 10px;
}

h4, .h4 {
    font-size: 20px;
    line-height: 25px;
    font-weight: 800;
    margin: 10px;
}

h5, .h5 {
    font-size: 13px;
    line-height: 18px;
    font-weight: 700;
    margin: 5px;
}


p, .p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
    margin: 0;
}

a.btn {
    font-size: 20px;
    line-height: 25px;
    font-weight: 800;
    display: block;
}

@media screen and (max-width: 769px) {
    h1, .h1 {
        font-size: 30px;
        line-height: 35px;
    }
    
    h2, .h2 {
        font-size: 25px;
        line-height: 30px;
    }
    
    h3, .h3 {
        font-size: 20px;
        line-height: 25px;
    }
    
    h4, .h4 {
        font-size: 18px;
        line-height: 23px;
        margin: 10px;
    }

    h5, .h5 {
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        margin: 5px;
    }
    
    p, .p {
        font-size: 16px;
        line-height: 25px;

    }
    a.btn {
        font-size: 18px;
        line-height: 23px;
    }
  }
/*---text styles ---*/

.button330 {
    max-width: 330px !important;
    width: 100%;
    text-align: center;
}

.button-outlined {
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    color: #7C90FF;
    background-color: white;
    border: solid 4px #7C90FF;
    padding: 20px 40px;
    min-width: 300px;
}


/*--- layout style---*/
.container {
    box-sizing: border-box; 
    padding: 0px 10px;
}

.body {
    box-sizing: border-box; 
    max-width: 1200px;
    width: 100%;
    margin: auto;
    border-radius: 5px;
    margin-top: 40px;
}

.body_container {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin: auto;
}

@media screen and (max-width: 800px) {
    .body_container {
        grid-template-columns: 49% 49%;
    }
}

img {
    object-fit: cover;
    border-radius: 10px;
    box-sizing: border-box; 
}

.design_posts img {
    border-radius: 0px;
}

/*--- Etc ---*/
.empty:empty {
    display: none;
}

/*-- Zoom --- */
[data-rmiz-wrap='visible'],
[data-rmiz-wrap='hidden'] {
 display: block !important;
 
}

input {
    border: 1px solid gray;
    font-size: 18px;
    padding: 10px;
    max-width: 500px;
    width: 100%;
    border-radius: 8px;
    margin: 10px 0;
}

.Zoom-img-container div {
    width: 100%;
}

.codeBlock div {
    border-radius: 10px !important;
}

@media screen and (max-width: 769px) {
    .roles p {
    font-size: 13px;
    }
}