/* Add a black background color to the top navigation */
.navbar {
  background-color: #ffffff;
  height: 80px;
  box-shadow: 0px 0px 15px #8383961f;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}

.topnav {
  overflow: hidden;
  max-width: 1240px;
  width: 100%;
  margin: auto;
  padding: 0 10px;
  /* background-color: white; */
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 30px 20px;
  text-decoration: none;
  font-size: 16px;
  /* font-family: 'Noto Sans JP', sans-serif; */
  font-weight: 600;
}

/* Add an active class to highlight the current page */
a.navbar_logo {
  /* background-color: #4CAF50;
      color: white; */
  font-size: 20px;
  font-weight: 800;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
}

.navbar_menu {
  float: right;
  margin-right: 20px;
}

/* Dropdown container - needed to position the dropdown content */
.dropdown {
  float: left;
  overflow: hidden;
  /* padding-top: 4px; */
}

/* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: black;
  padding: 30px 20px;
  font-weight: 600;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  /* background-color: #f9f9f9; */
  min-width: 160px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 100;
  top:80px;
  margin-left: -90px;
  background-color: #a8befe;
}

/* Style the links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a dark background on topnav links and the dropdown button on hover */
.topnav a:hover,
.dropdown:hover .dropbtn {
  /* background-color: #f8f8fb; */
}

/* Add a grey background to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #8ba5f3;
  color: black;
}

/* Show the dropdown menu when the user moves the mouse over the dropdown button */
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:active .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 600px) {
  .dropdown:hover .dropdown-content {
    display: contents;
  }
    }

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 600px) {
  .topnav {
    padding: 0px;
    background-color: white;
    padding-top: 10px;
  }
  .topnav a:not(:first-child),
  .navbar_menu a,
  .dropdown .dropbtn {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
  .navbar_menu {
    float: initial;
    background-color: #efeff9;
    margin-right: 0;
  }

  /* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive .dropdown {
    float: none;
  }
  .topnav.responsive .dropdown-content {
    position: relative;
  }
  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}
